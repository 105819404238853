import React, { useEffect } from "react";
import Header from "../components/Header";
import "../styles/Home.css";
import Card from "../components/Card";
import illustration from "../Frame 1.png";
import { Link } from "react-scroll";
import Aos from "aos";
import logo from "../elink new.png";
import "aos/dist/aos.css";
import Slider from "react-slick";
import insta from "../instagram.png";
import whatsapp from "../social.png";
import contactus from "../undraw_contact_us_re_4qqt.svg";
import clients from "../data/images";
import CardData from "../data/CardData";
import ContactForm from "../components/ContactForm";

function Home() {
  useEffect(() => {
    Aos.init();
  }, []);

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "300px",
    slidesToShow: 1,
    speed: 500,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      <Header />

      <div id="landing_section">
        <div className="landing_left">
          <h1>
            Where Connectivity <br /> shapes <span>Brands</span>
          </h1>
          {[
            "Boost organic traffic",
            "Get more sales",
            "Tailor-made strategies",
          ].map((caption) => (
            <p>
              <span class="material-symbols-outlined">check</span> {caption}
            </p>
          ))}
          <br />
          <Link to="contact_us" smooth={true} spy={true}>
            <button className="button">Request Quote</button>
          </Link>
        </div>
        <div className="landing_image" data-aos="zoom-out">
          <img src={illustration} alt="" />
        </div>
      </div>
      <div id="service_section" data-aos="fade-up">
        <h2>Our Services</h2>
        <p>
          Whether you are a new brand and want to take it from zero to thousands
          of traffic per month… or you’ve been around for decades but suddenly
          lost a big chunk of your traffic… our company will help you bring in
          quality traffic. With a long-term marketing strategy that’s tailored
          to your business.
        </p>
        <br />

        <div className="cards" data-aos="fade-up">
          {CardData.map((card) => (
            <Card
              heading={card.heading}
              content={card.content}
              icon={card.icon}
            />
          ))}
        </div>
      </div>

      <div id="clients_section" data-aos="fade-up">
        <h2>Our Top Clients</h2>
        <div className="slider-container">
          <Slider {...settings} className="slider">
            {clients.map((client) => (
              <img src={client} alt="" className="client_logo" />
            ))}
          </Slider>
        </div>
        <div className="clients_mobile">
          {clients.map((client) => (
            <img src={client} alt="" className="client_logo" />
          ))}
        </div>
      </div>
      <div id="contact_us" data-aos="fade-up">
        <h2>Contact Us</h2>

        <div className="contactus_flex">
          <div>
            <h3>Get Started Right Now!</h3>
            <p>
              <b>Phone:</b> +91 9908331555
            </p>
            <p>
              <b>Email:</b> elink.connects@gmail.com
            </p>
            <img
              src={contactus}
              alt=""
              height={270}
              width={270}
              style={{ marginTop: "-30px" }}
            />
          </div>
          <ContactForm />
        </div>

        <div className="contact_mobile">
          <ContactForm />
        </div>
      </div>
      {/* <h2>Blogs</h2>
      <div
        className="blogs"
        style={{
          marginBottom: "25em",
          display: "flex",
          justifyContent: "space-around",
        }}>
        {["Lorem Ipsum", "Lorem Ipsum", "Lorem Ipsum"].map((blog) => (
          <Card heading={blog} content={blog} icon={blog} />
        ))}
      </div> */}
      <div className="footer">
        <div>
          <img src={logo} alt="logo" className="logo" />
        </div>
        <div>
          <h4>Quick Links</h4>
          <Link to="landing_section" smooth={true} spy={true} className="quick">
            <p>Home</p>
          </Link>
          <Link to="service_section" smooth={true} spy={true} className="quick">
            <p>Services</p>
          </Link>
          <Link to="clients_section" smooth={true} spy={true} className="quick">
            <p>Clients</p>
          </Link>
          <Link to="contact_us" smooth={true} spy={true} className="quick">
            <p>Contact Us</p>
          </Link>
        </div>
        <div>
          <h4>Social</h4>
          <p>
            <img
              src={insta}
              height={20}
              style={{ verticalAlign: "middle", marginRight: "10px" }}
              alt=""
            />
            <a
              href="https://www.instagram.com/elinksocial?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
              target="_blank"
              rel="noreferrer">
              Instagram
            </a>
          </p>
          <p>
            <img
              src={whatsapp}
              height={20}
              style={{ verticalAlign: "middle", marginRight: "10px" }}
              alt=""
            />
            <a
              href="https://api.whatsapp.com/send/?phone=919908331555&text&type=phone_number&app_absent=0"
              target="_blank"
              rel="noreferrer">
              Whatsapp
            </a>
          </p>
        </div>
        <div>
          <h4>Address</h4>
          <p>
            H.no 2-3-581/1 kala nilayam Road no 1 . Srinagar colony Alkapuri x
            roads Nagole 68
          </p>
        </div>
      </div>

      <div className="footer_mobile">
        <div>
          <h4>Social</h4>
          <p>
            <img
              src={insta}
              height={20}
              style={{ verticalAlign: "middle", marginRight: "10px" }}
              alt=""
            />
            <a href="https://www.instagram.com/elinksocial?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==">
              Instagram
            </a>
          </p>
          <p>
            <img
              src={whatsapp}
              height={20}
              style={{ verticalAlign: "middle", marginRight: "10px" }}
              alt=""
            />
            <a
              href="https://api.whatsapp.com/send/?phone=919908331555&text&type=phone_number&app_absent=0"
              target="_blank"
              rel="noreferrer">
              Whatsapp
            </a>
          </p>
        </div>
        <div>
          <h4>Address</h4>
          <p>
            H.no 2-3-581/1 kala nilayam Road no 1 . Srinagar colony Alkapuri x
            roads Nagole 68
          </p>
        </div>
      </div>
    </>
  );
}

export default Home;
